exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-ai-insights-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/posts/ai-insights.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-ai-insights-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-customer-stories-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/posts/customer-stories.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-customer-stories-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-product-updates-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/posts/product-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-product-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-security-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/posts/security.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-security-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-tips-tricks-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/posts/tips-tricks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-tips-tricks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-use-cases-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/posts/use-cases.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-use-cases-mdx" */),
  "component---src-templates-guide-post-js-content-file-path-src-guides-getting-started-mdx": () => import("./../../../src/templates/GuidePost.js?__contentFilePath=/builds/snapvault1/shareir-backend/landing/src/guides/getting-started.mdx" /* webpackChunkName: "component---src-templates-guide-post-js-content-file-path-src-guides-getting-started-mdx" */)
}

